import React from "react"
import '../styles/global.css'

export default() => (
    <section class="home-hero">
		<div class="container">
			<img src={require("../images/capturesound.png")} width="840" height="103" alt="portfolio item" class="title"></img>
		
		</div>
	</section>
)